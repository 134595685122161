export const tabList = [
  {
    label: '档口在售',
    value: 3,
  },
  {
    label: '档口下架',
    value: 2,
  },
];
