import { RightOutlined } from '@ant-design/icons';
import { message, Modal, Space, Button } from 'antd';
import type { BaseData } from 'egenie-utils';
import { NormalProgramme, request, MainSubStructureModel, ImgFormatter } from 'egenie-utils';
import { observable, action, computed } from 'mobx';
import React from 'react';
import { api } from '../../utils';
import styles from './index.less';

export default class Store {
  constructor() {
    this.init();
  }

  @observable
  public activeKey = -1;

  @computed
  public get selectedIds() {
    return Array.from(this.grid.gridModel.selectedIds, (el) => Number(el));
  }

  @action
  public onTabsChange = (activeKey: number) => {
    this.activeKey = activeKey;
    console.log(activeKey);
    this.programme.filterItems.updateFilterItem([
      {
        field: 'status',
        showItem: this.activeKey === -1,
      },
    ]);
    this.grid.onQuery();
  };

  @action
  public unCollect = async(ids: number[]): Promise<void> => {
    const res: BaseData<unknown> = await request({
      url: api.batchCancelCollect,
      method: 'post',
      data: { ids: ids?.join(',') },
    });
    this.refresh();
    message.success(res?.info || '');
  };

  @action
  private onClickBatchCancel = (ids: number[]) => {
    if (!ids?.length) {
      return message.warning('请选择至少一条数据');
    } else {
      return Modal.confirm({
        title: '是否取消收藏选中的款式?',
        onOk: () => {
          this.unCollect(ids);
        },
      });
    }
  };

  public init = (): void => {
    this.grid.getFilterParams = () => this.programme.filterItems.params;
    this.collectionShopList();
  };

  public refresh = (): void => {
    this.grid.onRefresh();
  };

  private collectionShopList = async(): Promise<void> => {
    const res: BaseData<Array<{ id: number; vendorName: string; }>> = await request({ url: api.collectionShopList });
    const shopList = res?.data?.map((el) => {
      return {
        label: el.vendorName,
        value: `${el.id}`,
      };
    });
    this.programme.filterItems.addDict({ vendorId: shopList });
  };

  public grid = new MainSubStructureModel({
    buttons: [
      {
        text: '批量取消收藏',
        handleClick: () => this.onClickBatchCancel(this.selectedIds),
      },
    ],
    grid: {
      columns: [
        {
          key: 'operation',
          name: '操作',
          formatter: ({ row }) => {
            const { goodsId } = row;
            return (
              <Space>
                <a
                  onClick={() => {
                    this.onClickBatchCancel([goodsId]);
                  }}
                >
                  取消收藏
                </a>
              </Space>
            );
          },
          width: 220,
        },
        {
          key: 'goodsName',
          name: '商品名称',
          width: 300,
          formatter: ({ row }) => {
            const { mainPicUrl, goodsName, goodsId, status } = row;
            return (
              <div className={styles.goodsInfo}>
                <ImgFormatter
                  borderRadius="4px"
                  height={56}
                  value={mainPicUrl || ' '}
                  width={56}
                />
                <div className={styles.info}>
                  <div
                    className={styles.productItemName}
                    title={goodsName}
                  >
                    {goodsName}
                  </div>
                  <div className={styles.saleStatus}>
                    <div className={status === 3 ? styles.shalve : styles.unshalve}>
                      {status === 3 ? '档口在售' : '档口已下架'}
                    </div>
                    <a
                      onClick={() => {
                        window.open(`/egenie-ts-vogue/goodsDetail/index?goodsId=${goodsId}`);
                      }}
                      type="link"
                    >
                      详情
                      <RightOutlined style={{ marginLeft: 2 }}/>
                    </a>
                  </div>
                </div>
              </div>
            );
          },
        },
        {
          key: 'vendorName',
          name: '档口名称',
          width: 220,
        },
        {
          key: 'price',
          name: '批发价',
          width: 220,
          formatter: ({ row }) => {
            const { price } = row;
            return (
              <div>
                {`¥${price}`}
              </div>
            );
          },
        },
        {
          key: 'createTime',
          name: '收藏时间',
        },
      ].map((el) => {
        return {
          ...el,
          resizable: true,
        };
      }),
      rows: [],
      showEmpty: true,
      primaryKeyField: 'goodsId',
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-ts-vogue/favoriteStyles/index',
      rowHeight: 84,
    },
    api: {
      onQuery: (params?) => {
        const { filterParams, ...rest } = params;
        if (filterParams?.collectTime) {
          const [
            startCreatedTime,
            endCreatedTime,
          ] = filterParams.collectTime.split(',');
          Object.assign(filterParams, {
            startCreatedTime,
            endCreatedTime,
          });
          delete filterParams.collectTime;
        }

        if (this.activeKey !== -1) {
          filterParams.status = this.activeKey;
        }
        return request({
          url: api.collectionList,
          method: 'post',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    hiddenSubTable: true,
  });

  public programme = new NormalProgramme({
    count: 6,
    filterItems: [
      {
        type: 'input',
        label: '名称/货号',
        field: 'search',
      },
      {
        type: 'select',
        label: '档口名称',
        field: 'vendorId',
        maxItemsLength: 400,
      },
      {
        type: 'select',
        label: '在售状态',
        field: 'status',
        data: [
          {
            label: '在售',
            value: '3',
          },
          {
            label: '下架',
            value: '2',
          },
        ],
      },
      {
        type: 'dateRange',
        label: '收藏时间',
        field: 'collectTime',
      },
    ],
    handleSearch: this.grid.gridModel.onQuery,
  });
}
