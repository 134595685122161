import { Card } from 'antd';
import { NormalProgrammeComponent, MainSubStructure } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import { TopTab } from '../components';
import { tabList } from './constant';
import styles from './index.less';
import Store from './store';

const store = new Store();

const FavoriteStyles: React.FC<unknown> = observer((props) => {
  const { activeKey, onTabsChange, programme, grid } = store;
  return (
    <div className={styles.page}>
      <TopTab
        activeKey={activeKey}
        list={tabList}
        onTabChange={onTabsChange}
      />
      <Card className={styles.card}>
        <NormalProgrammeComponent store={programme}/>
      </Card>
      <div className={styles.table}>
        <MainSubStructure store={grid}/>
      </div>
    </div>
  );
});

export default FavoriteStyles;
